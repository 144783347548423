'use client'

import React, { ReactNode } from 'react';
import { Button } from 'antd';
import { useRouter } from 'next/navigation';

export const BackButton = ({ className, children }: { className?: string, children: ReactNode }) => {
    const router = useRouter();

    return (
        <Button
            className={className}
            onClick={() => router.back()}
        >
            {children}
        </Button>
    );
};
